import { Progress, Typography } from "@material-tailwind/react";
import React from "react";
import image from "../assist/about1.png";
import {
  FaFacebookF,
  FaGitAlt,
  FaGripfire,
  FaLinkedinIn,
  FaTwitter,
  FaUserTie,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
const AboutComponent = () => {
  return (
    <section className="py-[30px] md:py-[80px]">
      <div className="container">
        <div className="grid grid-cols-12 gap-[20px]">
          <div className="col-span-12 lg:col-span-4 lg:pr-[30px]">
            <div className="menuBox" data-aos="fade-up" data-aos-delay="50">
              <div className=" inline-block rounded-full border border-text px-[20px] py-[5px]">
                <div className="flex items-center gap-[6px]">
                  <span>
                    <FaUserTie className="fa-light fa-user text-[14px] text-white" />
                  </span>
                  <span className="pl-[6px] text-[14px] text-white">
                    About Us
                  </span>
                </div>
              </div>
            </div>
            <br />
            {/* Profile Info */}
            <div className="info-card mt-[10px] md:mt-[30px]">
              <div className="rounded-[20px]  bg-card px-[30px] py-[40px]">
                <div>
                  <div className="mt-[25px]">
                    <div className="text-center">
                      <h3
                        className="wow fadeIn animated text-[24px] font-semibold   text-white"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        Hy! I’m
                        <span className="text-theme"> MD:Rubel!</span>
                      </h3>
                      <p
                        className="mt-[6px] text-text"
                        data-aos="fade-up"
                        data-aos-delay="150"
                      >
                        Software Engineers
                      </p>
                      <div
                        className="  mt-[26px] flex justify-center"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <img
                          src={image}
                          alt="Lariv portfolio web template"
                          className="w-full rounded-2xl object-cover"
                        />
                      </div>
                      <div
                        className="mt-[26px]"
                        data-aos="fade-up"
                        data-aos-delay="250"
                      >
                        <p
                          className="text-[20px] text-text"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                          hmrubel0143@gmail.com
                        </p>
                        <p
                          className="mt-[10px] text-text"
                          data-aos="fade-up"
                          data-aos-delay="150"
                        >
                          H-32,R-16,Sector-11 <br />
                          Uttara, Dhaka, Bangladesh
                        </p>
                      </div>
                      <div
                        className=" mt-[30px] flex  justify-center gap-3"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <Link
                          to="https://www.facebook.com/mernsolution"
                          className="block"
                        >
                          <FaFacebookF className=" socialRound" />
                        </Link>
                        <Link to="#" className="block">
                          <FaTwitter className=" socialRound" />
                        </Link>
                        <Link
                          to="https://www.linkedin.com/in/mernsolution/"
                          className="block"
                        >
                          <FaLinkedinIn className=" socialRound" />
                        </Link>
                        <Link to="https://github.com/mernsolution" className="block">
                          <FaGitAlt className=" socialRound" />
                        </Link>
                      </div>
                      <div
                        className=" mt-[30px]"
                        data-aos="fade-up"
                        data-aos-delay="250"
                      >
                        <a
                          href="assets/pdf/Resume-of-Amit-Biswas-MERN.pdf"
                          className="block rounded-full border-2 border-theme bg-theme py-[10px] font-medium text-white transition-all duration-300 hover:bg-transparent "
                        >
                          Download CV
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 lg:col-span-8 ">
            <div className="mt-[80px]">
              <h2
                className="text-[32px] font-semibold uppercase  leading-tight text-white md:text-[52px]"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                I can develop that
                <br className="hidden md:block" />
                help
                <span className="text-theme"> people!</span>
              </h2>
              <p
                className="mt-[20px] text-text"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Since starting my excursion as an independent creator almost
                quite a while back, I've accomplished remote work for
                organizations, counseled for new companies, and teamed up with
                capable individuals to make computerized items for both business
                and purchaser use. I'm discreetly sure, normally inquisitive,
                and ceaselessly dealing with further developing my chops each
                plan issue in turn.
              </p>
            </div>

            {/* Personal Information */}
            <div className="mt-[60px]">
              <h2
                className="text-[32px] font-semibold"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                Personal Info :
              </h2>
              <div className="mt-[20px] grid grid-cols-12 ">
                <div
                  className="col-span-6"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <p className=" text-[18px] font-medium">Name</p>
                  <p className="mt-2 text-[16px] text-text">
                    MD: RUBEL (Unus Mia)
                  </p>
                </div>
                <div
                  className="col-span-6"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <p className=" text-[18px] font-medium">Birthday</p>
                  <p className="mt-2 text-[16px] text-text">Dec, 1997</p>
                </div>
              </div>
              <div className="mt-[20px] grid grid-cols-12">
                <div
                  className="col-span-6"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <p className=" text-[18px] font-medium">Email</p>
                  <p className="mt-2 text-[16px] text-text">
                    hmrubel0143@gmail.com
                  </p>
                </div>
                <div
                  className="col-span-6"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <p className=" text-[18px] font-medium">Skype</p>
                  <p className="mt-2 text-[16px] text-text">
                    {" "}
                    <a href="https://join.skype.com/invite/wQ3ddyHsXs1Z">
                      MD Rubel
                    </a>
                  </p>
                </div>
              </div>
            </div>

            {/* Working Experience Information */}
            <div className="mt-[60px]">
              <h2
                className="text-[32px] font-semibold"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Job <span className="text-theme">Experience:</span>
              </h2>
              <div className="items mt-[40px] grid gap-[40px]">
                <div className="item relative grid gap-[40px] border-l-[2px] border-text pb-[16px] pl-[35px]">
                  <FaGripfire className="absolute left-[-16px] top-[-25px] text-[30px] text-text" />
                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Jun 2024 - Present
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Company : Rainbow-Themes
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Full Stack Developer (MERN)
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Since starting my excursion as an independent creator
                      almost quite a while back, I've accomplished remote work
                      for organizations, counseled for new companies, and teamed
                      up with capable individuals to make computerized items for
                      both business and purchaser use.
                    </p>
                  </div>

                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Apr 2023 - Present
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Author on CodeCanyon
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Full Stack Developer
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Since starting my excursion as an independent creator
                      almost quite a while back, I've accomplished remote work
                      for organizations, counseled for new companies, and teamed
                      up with capable individuals to make computerized items for
                      both business and purchaser use.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Working Experience Information */}
            <div className="mt-[60px]">
              <h2
                className="text-[32px] font-semibold"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Professional<span className="text-theme"> Projects :</span>
              </h2>
              <div className="items mt-[40px] grid gap-[40px]">
                <div className="item relative grid gap-[40px] border-l-[2px] border-text pb-[16px] pl-[35px]">
                  <FaGripfire className="absolute left-[-16px] top-[-25px] text-[30px] text-text" />
                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      August 2024 - Running
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Onlinemart E-commerce Platform
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Verify by ThemeFrost (Envato)
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Frontend ➥ 💻 Next js, Axios, Redux Toolkit,
                      Bootstrap5, SCSS !✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Backend ➥ 💻 Node Js, Express Js✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Database ➥ MongoDB, Advance Aggregation,✨
                    </p>

                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Payment Gateway ➥ PayPal, bkash ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Project Deeply ➥ cPanel ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 View Project ➥ 🔗{" "}
                      <a href="https://themeforest.net/item/aiwave-ai-saas-website-dashboard-html5-ui-kit/51144434?s_rank=2">
                        Live Link
                      </a>{" "}
                      🌐
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <a href="https://codecanyon.net/item/chat-gpt-smartai-writing-assistant-reactjs-openai-chatbot/46207622">
                        Read More
                      </a>
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Jan 2024 - Jun 2024
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Aiwave - AI SaaS Website & AI Tool
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Verify by ThemeFrost (Envato)
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Frontend ➥ 💻 Next js, Axios, Redux Toolkit,
                      Bootstrap5, SCSS !✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Backend ➥ 💻 Node Js, Express Js, OpenAI API✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Database ➥ MongoDB, Advance Aggregation,✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Payment Gateway ➥ PayPal, Stripe ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Project Deeply ➥ cPanel ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 View Project ➥ 🔗{" "}
                      <a href="https://themeforest.net/item/aiwave-ai-saas-website-dashboard-html5-ui-kit/51144434?s_rank=2">
                        Live Link
                      </a>{" "}
                      🌐
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <a href="https://codecanyon.net/item/chat-gpt-smartai-writing-assistant-reactjs-openai-chatbot/46207622">
                        Read More
                      </a>
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Jun 2023 - August 2023
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Smart AI Content writing tool (SAAS)
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Verify by CodeCanyon (Envato)
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Frontend ➥ 💻 React js, React Bootstrap, Axios, Redux
                      Toolkit, Bootstrap5 !✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Backend ➥ 💻 Node Js, Express Js, NLP, OpenAI API✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Payment Gateway ➥ PayPal, Stripe ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Project Deeply ➥ cPanel ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 View Project ➥ 🔗{" "}
                      <a href="https://codecanyon.net/item/chat-gpt-smartai-writing-assistant-reactjs-openai-chatbot/46207622">
                        Live Link
                      </a>{" "}
                      🌐
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <a href="https://codecanyon.net/item/chat-gpt-smartai-writing-assistant-reactjs-openai-chatbot/46207622">
                        Read More
                      </a>
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Jan 2023 - Jun 2023
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Nimble website & AI Content writing tool (SAAS)
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Verify by CodeCanyon (Envato)
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Frontend ➥ 💻 React js, React Bootstrap, Axios, Redux
                      Toolkit, Bootstrap5 !✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Backend ➥ 💻 Node Js, Express Js, NLP, OpenAI API✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Payment Gateway ➥ PayPal, Stripe ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 Project Deeply ➥ cPanel ✨
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      🌟 View Project ➥ 🔗{" "}
                      <a href="https://nimbleai.mernsolution.com/">Live Link</a>{" "}
                      🌐
                    </p>
                    <p
                      className="mt-[5px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <a href="https://codecanyon.net/item/chat-gpt-smartai-writing-assistant-reactjs-openai-chatbot/46207622">
                        Read More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Skill Information */}
            <div className="mt-[60px]">
              <h2
                className="text-[32px] font-semibold"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                My <span className="text-theme">Skills:</span>
              </h2>
              <div className="items mt-[40px] grid gap-[40px]">
                <div className="item relative grid gap-[40px] border-l-[2px] border-text pb-[16px] pl-[35px]">
                  <FaGripfire className="absolute left-[-16px] top-[-25px] text-[30px] text-text" />

                  <div>
                    <h3
                      className="mt-[5px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Language
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      JavaScript, TypeScript, PHP
                    </p>
                  </div>

                  <div>
                    <h3
                      className="mt-[0px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Frontend Framework
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      React js, Next js,Vue Js, Redux Toolkit, Bootstrap 5,Tailwind CSS
                    </p>
                  </div>
                  <div>
                    <h3
                      className="mt-[0px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Backend Framework
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Express.js, Laravel
                    </p>
                  </div>
                  <div>
                    <h3
                      className="mt-[0px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Database
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      MongoDB, Mysql
                    </p>
                  </div>
                
                  <div>
                    <h3
                      className="mt-[0px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                       Tools & Softwares
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      VS Code, npm, yarn, Git, JIRA, Postman, Studio 3T
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Education Information */}
            <div className="mt-[60px]">
              <h2
                className="text-[32px] font-semibold"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Educational <span className="text-theme">Qualifications:</span>
              </h2>
              <div className="items mt-[40px] grid gap-[40px]">
                <div className="item relative grid gap-[40px] border-l-[2px] border-text pb-[16px] pl-[35px]">
                  <FaGripfire className="absolute left-[-16px] top-[-25px] text-[30px] text-text" />

                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      2021 - Present
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Web Development
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Ostad Online Platform
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="250"
                    >
                      Since starting my excursion as an independent creator
                      almost quite a while back, I've accomplished remote work
                      for organizations, counseled for new companies, and teamed
                      up with capable individuals to make computerized items for
                      both business and purchaser use.
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      2021 - Present
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Web Design
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      ShikbeSobi
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="250"
                    >
                      Since starting my excursion as an independent creator
                      almost quite a while back, I've accomplished remote work
                      for organizations, counseled for new companies, and teamed
                      up with capable individuals to make computerized items for
                      both business and purchaser use.
                    </p>
                  </div>

                  <div>
                    <p
                      className="text-text"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      2014 - 2018
                    </p>
                    <h3
                      className="mt-[10px] text-[20px] font-medium text-white md:text-[25px]"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      BSS
                    </h3>
                    <p
                      className="mt-[5px] text-[18px] font-medium text-text"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      University of Dhaka (Affiliate)
                    </p>
                    <p
                      className="mt-[15px] text-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Since starting my excursion as an independent creator
                      almost quite a while back, I've accomplished remote work
                      for organizations, counseled for new companies, and teamed
                      up with capable individuals to make computerized items for
                      both business and purchaser use.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutComponent;
